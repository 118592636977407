import React from "react"
import { motion } from "framer-motion"

const Logo = props => (
  <motion.div whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.95, y: "5px" }}>
    <svg
      version="1.1"
      className={props.baseLayer}
      alt={props.siteTitle}
      x="0px"
      y="0px"
      viewBox="0 0 150 100"
    >
      <g>
        <path
          className={props.color0}
          d="M83.1,47.8c-2.1,0.1-3-1.5-3.2-3.7c-0.2-2.3-0.1-2.1,2-2.6c0.3-0.3,0.5-0.7,0.8-0.8c2.4-0.8,4.8-1,7.2-0.1
      c0.5,0.2,1.1,0.1,1.7,0.1c2.7-0.1,5.4-0.4,8.1-0.3c3.2,0.1,6.4,0.2,9.5,1.2c0.6,0.2,1.3,0.7,1.6,1.3c1.4,2.1,1.6,4.6,1.3,7
      c-0.1,0.8-0.9,1.7-1.7,2.3c-1.2,1-2.7,1.8-3.9,2.6c1.9,0.9,4,1.8,6,2.8c2.1,1.1,3.6,2.8,4.8,4.9c0.5-0.4,0.9-0.7,1.6-1.2
      c0.1,0.7,0.2,1.2,0.2,1.7c-0.5,3.1,0,6,2,8.5c1.8,2.4,4.5,3.4,7.3,4c4,0.8,8,0.6,11.9-0.7c2-0.7,2.7-1.6,0.7-3.1
      c-1.6-1.3-3.3-2.3-5.1-3.2c-4.1-2.1-8.4-3.9-12.5-6c-2.5-1.2-4.7-2.8-6.4-5c-1.2-1.5-1.5-3.3-1.1-5.3c0.3-1.7,0.8-3.4,1.7-4.9
      c0.9-1.5,2.4-2.2,3.9-2.7c3.3-1.1,6.8-1.6,10.3-1.7c2.7-0.1,5.4-0.2,8.1-0.2c1.3,0,2.6,0.1,3.9,0.2c2.1,0.3,4.7,4.1,3.5,6.8
      c-0.9,2-1.2,2.3-3.4,2.2c-2.1-0.1-4.3-0.3-6.4-0.2c-3.4,0.2-6.9,0.6-10.3,1c-0.3,0-0.6,0.2-1.1,0.3c2,0.8,3.8,1.6,5.5,2.3
      c4.9,2.2,9.6,4.7,13.2,8.7c2.3,2.6,3.6,5.6,3.2,9c-0.4,3.4-1.8,6.6-4.3,9c-1.9,1.9-4.5,2.8-7.2,3.2c-3.1,0.5-6.3,0.4-9.4-0.2
      c-5-0.9-8.4-4-10.8-8.3c-0.1-0.2-0.3-0.4-0.5-0.7c-0.5,0.4-0.9,0.8-1.3,1.1c-2,1.4-4.1,2.3-6.4,2.8c-5.6,1.3-11.3,0.8-17,0.6
      c-0.5,0-1.1-0.1-1.6-0.2c-0.9-0.3-1.7-0.2-2.2,0.8c-0.3,0.5-1.1,0.1-1.3,1c-0.1,0.5-0.6,0.9-1.1,1.5c-0.1-0.5-0.3-0.8-0.3-1.1
      c-0.1-0.3-0.1-0.7,0-1c0.1-1.6-0.1-2.9-1.8-3.8c-2.8-1.7-4.2-4.5-4.8-7.7c0-0.2-0.1-0.3-0.2-0.5c0-0.1,0-0.3,0-0.4
      c2.1,0.7,4.1,1.3,6.4,2.1c0-1.3,0-2.3,0-3.3c0-1.6-0.1-3.1-0.2-4.7c0-1.3-0.1-1.4-1.4-1.4c-0.8,0-1.8,0.1-2.5-0.2
      c-2.2-1.1-2.1-4.5-1.2-6.1c0-0.1,0.1-0.2,0.2-0.2c0.7-0.3,1.3-0.4,1.8-1.2c0.2-0.4,1.1-0.4,1.7-0.4c0.8,0,0.9-0.4,0.8-1
      C83.3,51.1,83.2,49.5,83.1,47.8z M91.4,72.1c0.4,0,0.7,0,1,0c2.5-0.2,5-0.3,7.4-0.6c3.4-0.4,6.5-1.5,9.6-3c1.1-0.6,1.2-1,0.4-2
      c-1.4-1.8-3.4-2.6-5.5-3.1c-2.5-0.6-5.1-1-7.7-1.4c-1.4-0.2-2.8-0.2-4.3-0.3C92.1,65.2,91.7,68.6,91.4,72.1z M92.5,50.8
      c1.9,0,8.6-2.5,9.7-3.6c-3.3,0-6.5,0-9.7,0C92.5,48.5,92.5,49.7,92.5,50.8z"
        />
        <path
          className={props.color0}
          d="M48.2,20.6c-2.6,0.5-4.8,1-7.1,1.5c-1.5,0.3-2.4-0.7-3.1-1.8c-1.6,0.6-3.2,1.2-4.8,1.8
      c-1.6,0.7-3.1,1.4-4.7,2.1c0,0.1,0,0.2,0,0.3c0.4,0.1,0.8,0.2,1.1,0.3c2.4,0.3,4.9,0.5,7.2,1c2.3,0.4,4.5,1.3,6.4,2.8
      c1.6,1.3,2.7,2.9,2.7,5.1c0,1.4,0,2.8-0.3,4.1c-0.4,1.8-1.6,3.3-3.2,4.3c-2.4,1.6-5.2,2.5-8.1,2.6c-3.1,0.1-5.8-1.4-7.9-3.7
      c-1.3-1.5-1.7-3.2-1.9-5.1c-0.2-1.6-0.1-3.1,1-4.6c0.3,0.9,0.5,1.6,0.7,2.3c1,3.5,4.2,5.2,7.8,4.7c2.8-0.4,5.3-1.3,7.6-3
      c1-0.8,0.9-1.6-0.3-2c-1.8-0.5-3.7-0.8-5.5-1.1c-3.2-0.5-6.4-0.8-9.5-1.5c-1.4-0.3-3.1-0.9-3.9-2.4c-0.8-1.4-1-3-0.8-4.7
      c0.2-2,1.6-3.1,3.1-4c2.7-1.7,5.8-2.8,8.9-3.7c0.7-0.2,1.4-0.5,2.1-0.7c1.1-0.3,2.1-0.6,3.1,0.1c0.3,0.2,0.8,0.1,1.2,0.1
      c2-0.4,4.1-0.8,6.1-1.2c0.2,0,0.5-0.2,0.7-0.3c1.3-0.6,2.7-1,4.2-0.7c0.8,0.2,1.7,0.2,2.5,0.2c2.3-0.1,4.6-0.2,6.9-0.4
      c2.1-0.2,4.2-0.5,6.3-0.7c0.8-0.1,1.7-0.2,2.5-0.2c4-0.2,8.1-0.3,12.1-0.4c0.2,0,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.2,0.7,0.2
      c1.7-0.2,3.5-0.5,5.2-0.5c3-0.1,6.1-0.1,9.1,0c2.3,0.1,4.5,0.3,6.8,0.4c0.1,0.7,0.2,1.2-0.7,1.4c-0.4,0.1-0.8,0.4-1.2,0.7
      c-0.1,0.1-0.1,0.4-0.1,0.6c-0.7,0.4-1.4,1-2.1,1.2c-1.6,0.4-3.2,0.6-4.9,0.8c-1.7,0.2-3.5,0.4-5.2,0.6c-0.1,1.2-0.2,2.4-0.3,3.4
      c3.8,0,7.4,0,11.1,0c-0.3,1.5-1.2,2.3-2.4,3.1c-2.8,2-6.1,2.6-9.4,3.2c-0.2,1.5-0.4,3-0.7,4.6c5-1.5,10-2.4,15.3-2.2
      c0.4-6.2,4.3-9.2,9.7-10.9c0-0.1,0-0.2-0.1-0.2c-0.4,0-0.7-0.1-1.1-0.1c-1.5-0.1-2.9-0.1-4.4-0.2c-1.5-0.1-2.1-1.1-2.2-2.3
      c-0.1-1.1,0.3-2.3,0.5-3.5c0-0.1,0.5-0.3,0.7-0.2c2.7,0.4,5.4,0.9,8,1.1c4.1,0.4,8.2,0.7,12.3,1c0.6,0,1.2,0.1,1.8,0.2
      c0.2,0,0.4,0.2,0.5,0.3c0.7,1.2,1.4,2.5,1.1,3.9c-0.1,0.8-0.5,1.7-1,2.2c-0.4,0.4-1.4,0.3-2.1,0.3c-2,0-4,0-6,0
      c-2.9,0.1-5.6,1-8.2,2.2c-2.2,1-4.1,2.3-5.6,4.2c-1.4,1.8-0.6,3.6,0.9,4.6c1.9,1.2,4.1,1.8,6.4,2c2,0.2,4.1,0.3,6.1,0.2
      c1.9-0.1,3.8-0.4,5.5-1.4c1.5-0.9,1.6-2.1,0.4-3.3c-1.5-1.5-3.4-1.9-5.3-2.4c-1-0.2-1.8-0.5-2.4-1.4c0.3-0.1,0.5-0.1,0.8-0.2
      c-0.5-0.1-0.9-0.2-1.3-0.4c0,0,0-0.1,0-0.1c0.4,0,0.8-0.1,1.5-0.1c-0.8-0.3-1.4-0.5-2-0.7c0-0.1,0-0.1,0-0.2c0.4,0,0.7-0.1,1.2-0.1
      c-0.5-0.3-0.9-0.5-1.3-0.7c0-0.1,0-0.2,0-0.3c0.7,0,1.4,0,2.1,0c3,0.2,5.7,1.1,7.9,3.2c1.8,1.7,3.3,3.6,3,6.2
      c-0.4,3-2.1,5.2-4.8,6.6c-2.1,1.1-4.4,1.6-6.8,1.7c-3.7,0.1-7.2-0.5-10.5-2.3c-2.4-1.3-4.1-3.2-5-5.8c-0.2,0.1-0.4,0-0.5,0.1
      c-1.4,1.6-3.4,1.5-5.2,1.9c-4,0.9-8,2-11.8,3.6c-0.8,0.3-1.6,0.7-2.4,1c-0.3,0.1-0.7,0.1-0.9,0c-1.2-0.6-2.2-3.4-1.7-4.7
      c0.1-0.4,0.5-0.7,0.7-1.1c-6.2,1.5-12.1,3.5-17.6,6.8c-0.6,0.3-1.1,0.3-1.4-0.2c-0.7-1.1-1.3-2.2-1.7-3.4c-0.3-0.9,0.3-1.6,1.5-2.3
      c-0.5-5.3-1-10.5-1.5-16c-2.1,0.3-4.1,0.6-6.1,0.9c0.1,1.5,0.3,2.9,0.4,4.2c0.1,4.1,0.2,8.3,0.2,12.4c0,0.9-0.6,1.9-1,2.8
      c-0.5,1.1-1.2,2.1-1.8,3.2c-0.2-0.2-0.4-0.3-0.6-0.4c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0-0.2,0-0.3,0C50.5,35.2,49.4,27.9,48.2,20.6z
       M79.4,20.3c-0.2,0.8-0.1,1.5-0.5,1.9c-0.6,0.8-1.4,1.9-2.3,2.1c-1,0.3-1.6,1-2.4,1.3c-1.8,0.6-3.6,1.2-5.4,1.9
      c-0.3,0.1-0.7,0.4-0.7,0.6c-0.1,1.3-0.2,2.7-0.2,4.1c4.8-1.8,9.7-3.1,14.8-3.3c0-5.5,0.1-10.8-0.7-16.3c-0.7,0.5-1.3,1-1.9,1.4
      c0.1,0.1,0.2,0.2,0.3,0.3c-0.7,0.5-1.4,1.2-2.2,1.5c-1.2,0.5-2.5,0.7-3.8,0.9c-2,0.4-3.9,0.8-5.9,1.2c0,1.3,0,2.4,0,3.6
      C72.1,21,75.7,20.7,79.4,20.3z"
        />
        <path
          className={props.color0}
          d="M53.8,49.3c-1.3,2.2-2.5,4.3-3.8,6.4c-0.5,0.9-0.5,1.3,0.3,2.1c2.2,2.2,4.4,4.4,6.5,6.7c0.2,0.3,0.2,1,0,1.4
      c-1,2.1-2.2,4.2-3.2,6.3c0,0,0,0.1,0,0.4c1.2-1,2.3-1.9,3.4-2.9c1.4-1.3,2.8-2.6,4.3-3.8c0.6-0.5,0.6-0.9,0.2-1.5
      c-1.6-2.1-3.3-4.3-4.8-6.4c-0.2-0.2-0.3-0.7-0.1-0.9c1.4-1.9,2.9-3.8,4.2-5.8c0.8-1.3,2.1-1.5,3.3-1.6c2.2-0.2,4.4-0.1,6.5,0.8
      c3.9,1.5,7.3,5.8,7.3,10.2c0,4.2-1.7,7.6-4.4,10.7c-2.6,3-5.9,5-9.1,7.1c-1.9,1.2-4,2.2-6.1,3.3c-2.1,1.1-4.3,2.1-6.5,3.2
      c-0.5,0.3-1.1,0.5-1.6,0.7c-0.2,0.1-0.6-0.1-0.8-0.3c-1.3-1.4-2.5-2.9-3.8-4.4c-1.5-1.8-3-3.6-4.4-5.5c-2.6-3.3-5.1-6.8-6.7-10.7
      c-1-2.5-1.7-5.1-1.6-7.7c0.1-5.7,3-10.1,8.9-11.6c3.4-0.9,6.6-0.2,9.5,1.7C52,47.7,52.8,48.5,53.8,49.3z"
        />
        <path
          className={props.color0}
          d="M20.1,47.3c-1.4-0.8-2.8-1.6-4.3-2.4c0-0.1,0-0.1,0-0.2c1.2,0.1,2.3,0.2,3.5,0.4c4.1,0.9,7,3.5,9.3,6.9
      c2.2,3.2,3.5,6.9,4.1,10.7c0.9,5.2,0.8,10.3-0.9,15.4c-0.8,2.5-2.1,4.8-3.9,6.6c-2,2-4.4,3.3-7.3,3.5c-1.8,0.1-3.5,0.4-5.3,0.4
      c-3.6-0.1-6.9-1.2-9.5-3.7c-3.3-3.4-4.7-7.5-3.4-12.2c0.7-2.7,2.7-4.2,5.4-4.7c1.6-0.3,2.5,0.5,2.4,2.1c-0.2,5.2,2.3,9.1,6.1,12.4
      c0.7,0.6,1.2,0.3,1.9-0.1c2.5-1.8,3.8-4.3,4.5-7.2c0.8-3.3,0.7-6.7,0.2-10.1c-0.8-5.5-2.6-10.6-5.8-15.2c-1-1.4-2.2-2.6-3.9-3.4
      c-0.2-0.1-0.4-0.3-0.4-0.4c0-0.2,0.2-0.5,0.4-0.5c0.6-0.1,1.4-0.2,1.9,0c1.6,0.6,3.2,1.3,4.7,2C20,47.4,20.1,47.4,20.1,47.3z"
        />
      </g>
    </svg>
  </motion.div>
)

export default Logo
