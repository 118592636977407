import React, { useState } from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import PropTypes from "prop-types"
import { Navbar, Nav } from "react-bootstrap"
import Logo from "../images/logo"
import MenuIcon from "../images/icons/menu_icon"
import CloseIcon from "../images/icons/close_icon"

const Header = ({ siteTitle }) => {
  const [onToggle, setToggle] = useState(false)
  const handleClick = () => setToggle(!onToggle)

  return (
    <header>
      <div className="nav_wrap">
        <Navbar expand="md">
          <Navbar.Brand>
            <Link to="/">
              <Logo baseLayer="logo" color0="color0" siteTitle={siteTitle} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleClick}>
            {onToggle ? (
              <CloseIcon baseLayer="icon closed" />
            ) : (
              <MenuIcon baseLayer="icon open" />
            )}
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <OutboundLink
                className="nav-link"
                href="https://www.instagram.com/fakesteezjobs/"
                target="_blank"
                rel="noreferrer noopener"
              >
                INSTAGRAM
              </OutboundLink>
              <OutboundLink
                className="nav-link"
                href="https://music.apple.com/us/artist/steez-jobs/1474225344"
                target="_blank"
                rel="noreferrer noopener"
              >
                APPLE MUSIC
              </OutboundLink>
              <OutboundLink
                className="nav-link"
                href="https://open.spotify.com/artist/2hSysGmMiNeZdNBp7yz41H"
                target="_blank"
                rel="noreferrer noopener"
              >
                SPOTIFY
              </OutboundLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
